<script lang="ts">
import {
  faUser,
  faUserCheck,
} from '@igefa-e-commerce/pattern-library-icons/fa/pro-solid-svg-icons'
import Button from '@igefa-e-commerce/pattern-library/src/components/Button/Button.svelte'
import type { Customer } from 'domain/model/Customer.ts'
import { afterUpdate, onDestroy, onMount } from 'svelte'
import {
  USER_ACCOUNT_CREATED_AND_USER_LOGGED_IN_EVENT,
  USER_ACCOUNT_CREATED_BROADCAST_CHANNEL,
} from '../../../consts.ts'
import { pages } from '../../../pages.ts'
import MyAccountPopover from '../../molecules/my-account-popover/MyAccountPopover.svelte'

export let loggedIn: boolean
export let customer: Customer | undefined = undefined

let open = false
let isMobile = false

const channel = new BroadcastChannel(USER_ACCOUNT_CREATED_BROADCAST_CHANNEL)

const handleChannelMessage = ({ data: message }: MessageEvent) => {
  if (message === USER_ACCOUNT_CREATED_AND_USER_LOGGED_IN_EVENT) {
    loggedIn = true
  }
}

const redirect = () => {
  if (!loggedIn) {
    window.location.href = pages.user.login()
  } else {
    window.location.href = pages.user.myAccount()
  }
}

onMount(() => {
  if (typeof window !== 'undefined') {
    channel.addEventListener('message', handleChannelMessage)
  }
})

onDestroy(() => {
  if (typeof window !== 'undefined') {
    channel.removeEventListener('message', handleChannelMessage)
  }
})

afterUpdate(() => {
  isMobile = window.innerWidth <= 768
})
</script>

{#if isMobile}
  <div class='user-icon'>
    <div class="icon">
      <Button
        dataTestId="my-account-icon-button"
        icon={loggedIn ? faUserCheck : faUser}
        iconType="top"
        variant="tertiary"
        on:click={redirect}
      />
    </div>
  </div>
{:else}
  <div class='user-icon' on:mouseleave={() => (open = false)} class:popover={open}>
    <MyAccountPopover {open} {customer} on:close={() => (open = false)}>
      <div class="icon">
        <Button
          dataTestId="my-account-icon-button"
          icon={loggedIn ? faUserCheck : faUser}
          iconType="top"
          variant="tertiary"
          on:click={redirect}
          on:mouseenter={() => (customer && (open = true))}
        />
      </div>
    </MyAccountPopover>
  </div>
{/if}

<style lang='scss'>
  @use '@igefa-e-commerce/pattern-library/src/css/spacers.scss';

  .user-icon {
    width: fit-content;

    &.popover {
      padding-bottom: 1em;
      margin-bottom: -1em;
    }
  }

  .icon {
    position: relative;
    width: fit-content;
    height: fit-content;
  }
</style>
